import Image from 'next/legacy/image';
import React from 'react';
import Modal from 'react-modal';
import { render } from 'storyblok-rich-text-react-renderer';
import Link from 'next/link';
import type { PopupStoryblok } from 'component-types-sb';

import IconClose from '@/components/icons/IconClose';

import styles from '../../styles/Popup.module.css';

export default function Popup({ title, link, image, message }: PopupStoryblok) {
  Modal.setAppElement('#modals');
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  let url: string | undefined = '';
  if (link) {
    if (link?.linktype === 'story') {
      url = `${process.env.NEXT_PUBLIC_APP_URL}/${link?.story?.full_slug}`;
    } else if (link.linktype === 'url') {
      url = link?.url;
    } else if (link?.linktype === 'email') {
      url = `mailto:${link?.email}`;
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName={styles.modal_overlay}
      shouldCloseOnOverlayClick={false}
      aria={{
        labelledby: 'popup-title',
        describedby: 'popup-body',
      }}
    >
      <div className={styles.modal_body}>
        <button
          onClick={closeModal}
          className={styles.modal_close}
          type="button"
          aria-label="close"
        >
          <IconClose fill="#fff" width={12} />
        </button>

        <div className={styles.wrap}>
          {url ? (
            <>
              {title && (
                <Link href={url} target={link?.target} id="popup-title">
                  <span className={styles.title}>{title}</span>
                </Link>
              )}

              <Link href={url} target={link?.target}>
                {image && image.filename && (
                  <div className={styles.image}>
                    <Image
                      src={image.filename}
                      alt={image.alt ?? ''}
                      layout="fill"
                      objectFit="cover"
                      objectPosition="center"
                    />
                  </div>
                )}
              </Link>

              {message && (
                <div className={styles.message}>{render(message)}</div>
              )}
            </>
          ) : (
            <>
              {title && (
                <div id="popup-title" className={styles.title}>
                  {title}
                </div>
              )}

              {image && image.filename && (
                <div className={styles.image}>
                  <Image
                    src={image.filename}
                    alt={image.alt ?? ''}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                  />
                </div>
              )}

              {message && (
                <div id="popup-body" className={styles.message}>
                  {render(message)}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
